#side-menu {
	width: var(--side-menu-width);
	transition: 300ms all ease;
	transform-origin: left;
	transform: scaleX(0) translateX(-100%);
	will-change: scale, translateX;
	border-right: 1px solid white;
}

#side-menu .logo {
	opacity: 0;
}

.menu-close #side-menu .logo {
	opacity: 1;
}

#side-menu .slogan {
	transform-origin: top;
	transform: scaleY(0);
}

.menu-close #side-menu .slogan {
	transform: scaleY(1);
}

.side-nav {
	z-index: 2;
}

.side-nav a {
	transform: scaleY(0);
	transform-origin: bottom;
}

.menu-close .side-nav a {
	transform: scaleY(1);
	/*transform-origin: top;*/
}

.side-copyright {
	transform: scaleY(0);
	transform-origin: bottom;
}

.menu-close .side-copyright {
	transform: scaleY(1);
}

.side-menu-bottom {
	bottom: -100px;
	z-index: 0;
}

.side-menu-bottom-2 {
	bottom: -65px;
	z-index: 0;
}

@keyframes openMenu {
	0% {
		transform: scaleX(0) translateX(-100%);
	}
	50% {
		transform: scaleX(var(--side-menu-anim-multiplier)) translateX(-100%);
	}
	100% {
		transform: scaleX(1) translateX(-100%);
	}
}

@keyframes closeMenu {
	0% {
		transform: scaleX(1) translateX(-100%);
	}
	50% {
		transform: scaleX(var(--side-menu-anim-multiplier)) translateX(-100%);
	}
	100% {
		transform: scaleX(0) translateX(-100%);
	}
}

@keyframes scaleInMenu {
	0% {
		transform: scaleY(0);
	}
	100% {
		transform: scaleY(1);
	}
}

@keyframes scaleOutMenu {
	0% {
		transform: scaleY(1);
	}
	100% {
		transform: scaleY(0);
	}
}

@keyframes fadeInMenu {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOutMenu {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes rootRight {
	0% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(
			calc(var(--side-menu-width) * var(--side-menu-anim-multiplier))
		);
	}
	100% {
		transform: translateX(var(--side-menu-width));
	}
}

@keyframes rootLeft {
	0% {
		transform: translateX(var(--side-menu-width));
	}
	50% {
		transform: translateX(
			calc(var(--side-menu-width) * var(--side-menu-anim-multiplier))
		);
	}
	100% {
		transform: translateX(0);
	}
}

.menu-open {
	animation: rootRight 600ms ease forwards;
}

.menu-close {
	animation: rootLeft 600ms ease forwards !important;
}

.menu-open #side-menu {
	animation: openMenu 600ms ease forwards;
}

.menu-close #side-menu {
	animation: closeMenu 600ms ease forwards !important;
}

.menu-open .logo {
	animation: fadeInMenu 150ms ease-in forwards;
	animation-delay: 200ms;
}

.menu-close .logo {
	animation: fadeOutMenu 200ms ease-in forwards;
	animation-delay: 270ms;
}

.menu-open .slogan {
	animation: scaleInMenu 200ms ease-in forwards;
	animation-delay: 200ms;
}

.menu-close .slogan {
	animation: scaleOutMenu 200ms ease-out forwards;
	animation-delay: 270ms;
}

.menu-open .side-nav a:nth-child(1) {
	animation: scaleInMenu 200ms ease-in forwards;
	animation-delay: 400ms;
}
.menu-open .side-nav a:nth-child(2) {
	animation: scaleInMenu 200ms ease-in forwards;
	animation-delay: 600ms;
}
.menu-open .side-nav a:nth-child(3) {
	animation: scaleInMenu 200ms ease-in forwards;
	animation-delay: 800ms;
}
.menu-open .side-nav a:nth-child(4) {
	animation: scaleInMenu 200ms ease-in forwards;
	animation-delay: 1000ms;
}

.menu-close .side-nav a {
	animation: scaleOutMenu 200ms ease-out forwards;
	animation-delay: 270ms;
}

.menu-open .side-copyright {
	animation: scaleInMenu 200ms ease-in forwards;
	animation-delay: 1200ms;
}

.menu-close .side-copyright {
	animation: scaleOutMenu 200ms ease-out forwards;
	animation-delay: 270ms;
}
