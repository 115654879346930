/* Offset fixed header for each section other than landing */

.home__portfolio .container,
.home__about .container,
.home__contact .container {
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: flex-start;
	align-items: center;
}

@media screen and (min-width: 1400px) {
	.home__portfolio .container,
	.home__about .container,
	.home__contact .container {
		padding-top: 80px;
		padding-bottom: 80px;
	}
}

/* Small PC screens */

@media screen and (min-width: 1024px) {
	.home__portfolio .container,
	.home__about .container,
	.home__contact .container {
		padding-top: 65px;
		padding-bottom: 65px;
	}
}

/* Tablet */

@media screen and (min-width: 600px) and (max-width: 1024px) {
	.home__portfolio .container,
	.home__about .container,
	.home__contact .container {
		padding-top: 90px;
		padding-bottom: 90px;
		justify-content: space-evenly;
	}
}

/* Mobile */

@media screen and (max-width: 600px) {
	.home__portfolio .container,
	.home__about .container,
	.home__contact .container {
		padding-top: 100px;
		padding-right: 30px;
		padding-left: 30px;
	}
}

@media screen and (max-width: 400px) {
	.home__portfolio .container,
	.home__about .container,
	.home__contact .container {
		padding-top: 65px;
	}
}

.seperator {
	margin-top: 1.5rem;
	width: 75px;
	border-bottom: 3px solid;
}

.corner-img {
	width: 300px;
}

@media screen and (max-width: 1536px) {
	.corner-img {
		width: 250px;
	}
}

@media screen and (max-width: 1280px) {
	.corner-img {
		width: 200px;
	}
}

@media screen and (max-width: 1400px) {
	.corner-img {
		display: none !important;
	}
}

/* Homepage Landing */

.bg-landing {
	background-image: url("../assets/mountains.jpg");
	background-attachment: fixed !important;
	user-select: none;
}

.bg-landing::before {
	content: "";
	background: linear-gradient(to top, rgb(36, 36, 80), rgb(42 42 82));
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0.7;
	top: 0;
	left: 0;
}

.bg-parallax-width {
	position: absolute;
	width: 110%;
	height: 100%;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	user-select: none;
}

.bg-parallax-cont::before {
	content: "";
	background: linear-gradient(
		to top,
		rgb(36, 36, 80),
		rgb(42 42 82)
	); /*linear-gradient(to top, rgb(36, 36, 80), rgb(42 42 82))*/
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0.7;
	top: 0;
	left: 0;
	z-index: 10;
}

.bg-parallax-cont img {
	will-change: transform;
}

.bg-parallax-cont img:nth-child(1) {
	top: -25% !important;
	height: 125%;
	z-index: 5;
}
.bg-parallax-cont img:nth-child(2) {
	top: -20% !important;
	height: 120%;
	z-index: 4;
}
.bg-parallax-cont img:nth-child(3) {
	top: -15% !important;
	height: 115%;
	z-index: 3;
}
.bg-parallax-cont img:nth-child(4) {
	top: -9.5% !important;
	height: 110%;
	z-index: 2;
}
.bg-parallax-cont img:nth-child(5) {
	top: -5% !important;
	height: 105%;
	z-index: 1;
}

.profile-pic {
	position: relative;
	user-select: none;
}

.profile-pic__image {
	position: relative;
	z-index: 3;
	border: 3px solid white;
}

.profile-pic__circles {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	z-index: 2;
	overflow: visible;
}

.circles__circle {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	border: 2px solid white;
	opacity: 0;
	border-radius: 50%;
	background-color: transparent;
}

.profile-pic__image:hover
	+ .profile-pic__circles
	.circles__circle:nth-child(1) {
	animation: radiateCircle3 450ms ease-out;
}

.profile-pic__image:hover
	+ .profile-pic__circles
	.circles__circle:nth-child(2) {
	animation: radiateCircle2 400ms ease-out 200ms;
}

.profile-pic__image:hover
	+ .profile-pic__circles
	.circles__circle:nth-child(3) {
	animation: radiateCircle1 350ms ease-out 400ms;
}

@keyframes radiateCircle1 {
	0% {
		width: 100%;
		height: 100%;
		opacity: 1;
	}
	50% {
		width: 105%;
		height: 105%;
	}
	100% {
		width: 110%;
		height: 110%;
		opacity: 0;
	}
}
@keyframes radiateCircle2 {
	0% {
		width: 100%;
		opacity: 1;
	}
	50% {
		width: 110%;
		height: 110%;
	}
	100% {
		width: 115%;
		height: 115%;
		opacity: 0;
	}
}
@keyframes radiateCircle3 {
	0% {
		width: 100%;
		height: 100%;
		opacity: 1;
	}
	50% {
		width: 115%;
		height: 115%;
	}
	100% {
		width: 120%;
		height: 120%;
		opacity: 0;
	}
}

#intro--tw {
	position: relative;
	color: #fff;
	white-space: nowrap; /* Keeps the content on a single line */
}

#intro--tw::after {
	content: "";
	position: absolute;
	right: -10px;
	top: 50%;
	transform: translateY(-50%);
	height: 20px;
	width: 7px;
	background-color: white;
	animation: tw-blink 1s step-end infinite;
}

/* The typewriter cursor effect */
@keyframes tw-blink {
	from,
	to {
		background-color: transparent;
	}
	50% {
		background-color: white;
	}
}

/* Homepage Portfolio */

.home__portfolio {
	background-color: #edf2f7;
}

.portfolio__bg {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: -1;
	padding-bottom: 0;
}

.portfolio__bg img {
	width: 75%;
	margin-left: auto;
}

.portfolio__item {
	transition: 0.3s all ease;
	filter: brightness(1);
	overflow: visible;
	position: relative;
}

.portfolio__item img {
	transition: 0.3s all ease;
	will-change: transform;
	-webkit-filter: drop-shadow(0px 5px 4px rgb(0, 0, 0, 0.4));
	filter: drop-shadow(0px 5px 4px rgb(0, 0, 0, 0.4));
}

.portfolio__item:hover {
	transition: 0.3s all ease;
	filter: brightness(1.1);
}

.portfolio__item:hover img {
	transition: 0.3s all ease;
	transform-origin: center;
	transform: scale(1.07);
}

/* Homepage About */

.home__about {
	z-index: -1;
	background-image: linear-gradient(to top, rgb(21, 24, 65), #282c5c);
}

.left-corner-img {
	z-index: -1;
	transform: scaleX(-1);
}

.download-cv {
	transition: 0.4s all ease;
}

.download-cv:hover {
	transition: 0.4s all ease;
}

.skillbar {
	background: rgb(21 25 62) !important;
	border-top-right-radius: 3px !important;
	border-bottom-right-radius: 3px !important;
	margin-bottom: 20px !important;
}

.skillbar-bar {
	border-radius: 0 !important;
}

.skillbar-percent {
	color: white !important;
	font-size: 12px !important;
	top: 50% !important;
	transform: translateY(-50%) !important;
}

/* Homepage Contact */

.home__contact {
	background-color: #edf2f7;
}

.home__contact .border-black {
	border-color: black;
}

.home__contact button {
	transition: 0.4s all ease;
}

.home__contact button:hover {
	transition: 0.4s all ease;
}
