/* Import Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=BioRhyme:wght@200;300;400;700;800&display=swap");

@font-face {
	font-family: "PG";
	src: local("PG"), url("./fonts/PG/PG.ttf") format("truetype");
}

:root {
	--side-menu-width: 350px;
	--side-menu-anim-multiplier: 1.17;
}

@media screen and (max-width: 768px) {
	:root {
		--side-menu-width: 300px;
	}
}

@media screen and (max-width: 360px) {
	:root {
		--side-menu-width: 275px;
	}
}

* {
	font-family: "Inter", "BioRhyme", sans-serif;
}

*::selection {
	background-color: #12153a !important;
	color: white !important;
}

.biorhyme {
	font-family: "BioRhyme", "Asap", sans-serif;
}

.pg {
	font-family: "PG", "Inter", "BioRhyme", sans-serif;
	text-transform: uppercase;
}

#root {
	transition: 0.3s all ease;
	overflow: visible !important;
	will-change: translateX;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
	transition: 0.8s all ease !important;
}

.fp-viewing-home #fp-nav ul li a span,
.fp-slidesNav ul li a span {
	background: white !important;
}

.fp-viewing-portfolio #fp-nav ul li a span,
.fp-slidesNav ul li a span {
	background: #191d49 !important;
}

.fp-viewing-about #fp-nav ul li a span,
.fp-slidesNav ul li a span {
	background: white !important;
}

.fp-viewing-contact #fp-nav ul li a span,
.fp-slidesNav ul li a span {
	background: #191d49 !important;
}

@media screen and (max-width: 768px) {
	#fp-nav.fp-right {
		right: 0px !important;
	}
}
