.loading-overlay {
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	max-width: 100%;
	height: 100vh;
	max-height: 100%;
	background-color: #12153a;
	z-index: 99999;
	transition: max-height 0.8s;
	transition-timing-function: cubic-bezier(0.77, -0.09, 0.54, 0.7);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.loading-overlay strong {
	transform: scaleX(0);
	transition: transform 0.4s ease, opacity 0.3s ease;
	border-bottom: 2px solid white;
}

.loading-overlay span {
	color: white;
	padding-bottom: 5px;
	transform: scaleX(0);
	transition: transform 0.4s ease, opacity 0.3s ease;
}

@media screen and (max-width: 768px) {
	.loading-overlay span {
		border: none !important;
	}
}

@media screen and (max-width: 480px) {
	.loading-overlay strong {
		font-size: 36px;
	}
	.loading-overlay span {
		font-size: 28px;
	}
}
