.fp-viewing-home header {
	padding-top: 1.25rem;
	padding-bottom: 1.75rem;
	background: transparent;
}

.fp-viewing-home #header-name {
	opacity: 0;
	font-size: 1.875rem;
	line-height: 2.25rem;
}

.fa-bars {
	transition: 300ms all ease;
}

.fa-bars:hover {
	transform: scale(1.2);
}
